import React, { useMemo } from "react";
import { useSwipeable } from "react-swipeable";
import { navigate } from "gatsby";
import classNames from "classnames";

import { BubbleContent } from "../../common/BubbleContent";
// import { Circles } from "../../common/Circles";
// import { Markdown } from "../../common/Markdown";

import { StoryItem } from "./common";
import styles from "./StoriesList.module.scss";
import { motion } from "framer-motion";
import { StaggerText } from "../../common/StaggerText";
import { Touch } from "../../common/Touch";

interface StoryListItemProps
  extends Partial<Pick<StoryItem, "slug">>,
    Pick<StoryItem, "title" | "circle"> {
  variant?: number;
  onRef: (el: HTMLElement | null) => void;
  index: number;
  active?: boolean;
  past?: boolean;
  className?: string;
  smaller?: boolean;
  isVisible?: boolean;
}

const StoryListItem: React.FC<StoryListItemProps> = ({
  slug,
  title,
  circle = "001",
  variant = 3,
  onRef,
  index,
  active,
  className,
  smaller = false,
  isVisible,
}) => {
  const stagger = useMemo(() => {
    return title.split("\n");
  }, [title]);
  const { ref: swipeRef, ...restHandlers } = useSwipeable({
    delta: 200,
    onSwipedLeft: () => {
      slug && navigate(slug);
    },
  });
  return (
    <div
      {...restHandlers}
      className={classNames(
        styles.item,
        { [styles.smaller]: smaller },
        className
      )}
      ref={ref => {
        swipeRef(ref);
        onRef(ref);
      }}
      data-index={index}
      data-slug={slug}
    >
      <BubbleContent
        to={slug}
        circle={circle}
        className={styles.bubble}
        contentClassName={styles.itemContent}
        circleClassName={styles.circle}
        circleProps={{ stroke: true }}
        // squared={false}
        variant={variant}
        renderContent={isVisible}
      >
        <StaggerText show={active} text={stagger} />
        <motion.div
          initial={{ opacity: 0 }}
          animate={active ? { opacity: 1 } : { opacity: 0 }}
          transition={{
            duration: 1,
            delay: active ? 1 : 0,
          }}
          className={classNames(styles.touch, styles[`touch${variant}`])}
        >
          <Touch />
        </motion.div>
      </BubbleContent>
    </div>
  );
};

export { StoryListItem };
