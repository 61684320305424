import React, { useMemo } from "react";
import classNames from "classnames";

import styles from "./StaggerText.module.scss";

interface StaggerTextProps {
  text: string[];
  className?: string;
  show?: boolean;
}

const random = (max = 1) => Math.random() * max - max / 2;

const StaggerText: React.FC<StaggerTextProps> = ({
  text,
  className,
  show = true,
}) => {
  const textBlocks = useMemo(() => {
    const startOffset = random(0.25);

    return text.map((t, idx) => ({
      text: t.replace("@", ""),
      offset: Math.sin(startOffset + idx / 2) / 2,
      highlighted: t.indexOf("@") >= 0,
    }));
  }, [text]);

  return (
    <p
      className={classNames(styles.stagger, { [styles.show]: show }, className)}
    >
      {textBlocks.map((t, i) => (
        <span
          key={i}
          className={classNames({ [styles.force]: t.highlighted })}
          style={
            { "--left-offset": t.offset, "--index": i } as React.CSSProperties
          }
        >
          {t.text}
        </span>
      ))}
    </p>
  );
};

export { StaggerText };
