import { useRef, useCallback, useEffect } from "react";

type ListenerElement = {
  el: HTMLElement;
};

interface IntersectionObserverHookReturn {
  observeElement: (el: HTMLElement, id?: any) => void;
  unobserveElements: () => void;
  setObserverRoot: (el?: HTMLElement) => void;
}

export const useIntersectionObserver = (
  onIntersectCallback: IntersectionObserverCallback,
  ioOptions?: IntersectionObserverInit
): IntersectionObserverHookReturn => {
  const observer = useRef<IntersectionObserver | null>(null);
  const delayedListeners = useRef<ListenerElement[]>([]);
  const observedElements = useRef<Array<{ el: HTMLElement; id: any }>>([]);

  const onIntersect = useCallback<IntersectionObserverCallback>(
    (entries, observer) => {
      onIntersectCallback(entries, observer);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const setObserverRoot = useCallback(
    (el?: HTMLElement) => {
      if (observer.current) {
        return;
      }

      const options: IntersectionObserverInit = {
        threshold: [0.2],
        ...ioOptions,
      };

      if (el) {
        options.root = el;
      }

      const io = new IntersectionObserver(onIntersect, options);

      observer.current = io;

      if (delayedListeners.current.length) {
        delayedListeners.current.forEach(item => {
          io.observe(item.el);
        });
      }
    },
    [ioOptions, onIntersect]
  );

  const observeElement = useCallback((el: HTMLElement, id?: any) => {
    if (!observer.current) {
      delayedListeners.current.push({
        el,
      });
    } else {
      observer.current.observe(el);
    }
    observedElements.current.push({ id, el });
  }, []);

  const unobserveElements = useCallback(() => {
    observedElements.current.forEach(item => {
      observer.current?.unobserve(item.el);
    });
    observedElements.current = [];
  }, []);

  useEffect(() => {
    return () => observer.current?.disconnect();
  }, []);

  return {
    observeElement,
    unobserveElements,
    setObserverRoot,
  };
};
