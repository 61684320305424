import React from "react";
import classNames from "classnames";

import { ReactComponent as Circle001 } from "./assets/001.svg";
import { ReactComponent as Circle002 } from "./assets/002.svg";
import { ReactComponent as Circle003 } from "./assets/003.svg";
import { ReactComponent as Circle004 } from "./assets/004.svg";

import styles from "./Touch.module.scss";

interface TouchProps extends React.HTMLAttributes<HTMLSpanElement> {
  className?: string;
}

const Touch: React.FC<TouchProps> = ({ className, ...props }) => {
  const cls = classNames(styles.outer, className);

  return (
    <span className={cls} {...props}>
      <Circle004 className={classNames(styles.circle, styles.circleFour)} />
      <Circle003 className={classNames(styles.circle, styles.circleThree)} />
      <Circle002 className={classNames(styles.circle, styles.circleTwo)} />
      <Circle001 className={classNames(styles.circle, styles.circleOne)} />
    </span>
  );
};

export { Touch };
